// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-hersteller-konditionen-index-js": () => import("./../../../src/pages/hersteller-konditionen/index.js" /* webpackChunkName: "component---src-pages-hersteller-konditionen-index-js" */),
  "component---src-pages-hersteller-konditionen-pricing-js": () => import("./../../../src/pages/hersteller-konditionen/Pricing.js" /* webpackChunkName: "component---src-pages-hersteller-konditionen-pricing-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-index-page-index-js": () => import("./../../../src/pages/IndexPage/index.js" /* webpackChunkName: "component---src-pages-index-page-index-js" */),
  "component---src-pages-index-page-index-page-js": () => import("./../../../src/pages/IndexPage/IndexPage.js" /* webpackChunkName: "component---src-pages-index-page-index-page-js" */),
  "component---src-pages-vorsorgen-und-verfuegen-bestattung-online-planen-funeral-plan-js": () => import("./../../../src/pages/vorsorgen-und-verfuegen/bestattung-online-planen/FuneralPlan.js" /* webpackChunkName: "component---src-pages-vorsorgen-und-verfuegen-bestattung-online-planen-funeral-plan-js" */),
  "component---src-pages-vorsorgen-und-verfuegen-bestattung-online-planen-index-js": () => import("./../../../src/pages/vorsorgen-und-verfuegen/bestattung-online-planen/index.js" /* webpackChunkName: "component---src-pages-vorsorgen-und-verfuegen-bestattung-online-planen-index-js" */),
  "component---src-pages-vorsorgen-und-verfuegen-index-js": () => import("./../../../src/pages/vorsorgen-und-verfuegen/index.js" /* webpackChunkName: "component---src-pages-vorsorgen-und-verfuegen-index-js" */),
  "component---src-pages-vorsorgen-und-verfuegen-provision-js": () => import("./../../../src/pages/vorsorgen-und-verfuegen/Provision.js" /* webpackChunkName: "component---src-pages-vorsorgen-und-verfuegen-provision-js" */)
}

